import React from 'react';
import Containers from './Containers';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Containers />
    </div>
  );
}

export default App;
