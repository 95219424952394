import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ValidateEmail } from "../../../Utils";
import "./login.css";

type initialState = {
  userName: string;
  password: string;
};

type initialError = {
  userNameError: string;
  passwordError: string;
  invalideUserandPwd: string;
};

type event = {
  target: {
    name: string;
    value: string;
  };
};

function Login() {
  const history = useHistory();
  const [state, setState] = useState<initialState>({
    userName: "",
    password: "",
  });

  const [errorObj, setError] = useState<initialError>({
    userNameError: "",
    passwordError: "",
    invalideUserandPwd: "",
  });

  const handleChange = ({ target: { name, value } }: event) => {
    if (name === "userName") {
      setError({ ...errorObj, userNameError: "", invalideUserandPwd: "" });
    } else if (name === "password") {
      setError({ ...errorObj, passwordError: "", invalideUserandPwd: "" });
    }
    setState({ ...state, [name]: value });
  };

  const validateLoginFrom = () => {
    const errorMssage = { ...errorObj };
    errorMssage.userNameError = state.userName
      ? ValidateEmail(state.userName)
      : "User Name is requred.";
    errorMssage.passwordError = state.password ? "" : "password is requred.";
    setError(errorMssage);
    if (errorMssage.userNameError === "" && errorMssage.passwordError === "") {
      return true;
    }
  };

  const handleSubmit = () => {
    if (validateLoginFrom()) {
      const configValue: string = process.env.REACT_APP_USER_LIST as string;
      const match = JSON.parse(configValue).find(
        (userObj: initialState) =>
          userObj.userName === state.userName &&
          userObj.password === state.password
      );
      if (match) {
        // history.push({ pathname: "/home", state: { show: true } });
        history.push({ pathname: "/home", state: { show: true } });
      } else {
        setError({
          ...errorObj,
          invalideUserandPwd: "Please Enter Valid User Name OR Password.",
        });
      }
    }
  };

  const handleOnEnter = ({ code }: React.KeyboardEvent<HTMLInputElement>) => {
    if (code === "Enter") {
      handleSubmit();
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div>
          <h3>Sign In</h3>
          <div className="form-group">
            <label>User Name</label>
            <input
              type="text"
              className="form-control login-input-control"
              placeholder="Enter User Name"
              onChange={handleChange}
              onKeyPress={handleOnEnter}
              name="userName"
              value={state.userName}
            />
            {errorObj.userNameError && (
              <div className="error">{errorObj.userNameError}</div>
            )}
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control login-input-control"
              placeholder="Enter password"
              onChange={handleChange}
              onKeyPress={handleOnEnter}
              name="password"
              value={state.password}
            />
            {errorObj.passwordError && (
              <div className="error">{errorObj.passwordError}</div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-5">
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-info "
              >
                Sign In
              </button>
              {errorObj.invalideUserandPwd && (
                <div className="error text-center">
                  {errorObj.invalideUserandPwd}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(Login);
