import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
//import emailjs from "@emailjs/browser";

type initialState = {
  full_name: string;
  email: string;
  message: string;
};

type event = {
  target: {
    name: string;
    value: string;
  };
};

type useLocationObj = {
  state: {
    show: boolean | undefined;
  };
};

const loadScript = function (src: string) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
};

export default function Home() {
  const history = useHistory();
  const useLocationObj: useLocationObj = useLocation();
  // const [state, setState] = useState<initialState>({
  //   full_name: "",
  //   email: "",
  //   message: "",
  // });
  //const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (!useLocationObj?.state?.show) {
      history.push("/");
    }
  }, [useLocationObj?.state, history]);

  useEffect(() => {
    loadScript("../assets/custom.js");

    const RandomNumber: number = Math.floor(Math.random() * 7);
    let stylePath = "";
    if (RandomNumber === 0) {
      stylePath = "../../assets/css/blue.css";
    } else if (RandomNumber === 1) {
      stylePath = "../../assets/css/green.css";
    } else if (RandomNumber === 2) {
      stylePath = "../../assets/css/lblue.css";
    } else if (RandomNumber === 3) {
      stylePath = "../../assets/css/orange.css";
    } else if (RandomNumber === 4) {
      stylePath = "../../assets/css/purple.css";
    } else if (RandomNumber === 5) {
      stylePath = "../../assets/css/red.css";
    } else if (RandomNumber === 6) {
      stylePath = "../../assets/css/violet.css";
    }

    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath;

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, []);

  // const handleChange = ({ target: { name, value } }: event) => {
  //   setState({ ...state, [name]: value });
  // };

  // const sendEmail = (e: any) => {
  //   e.preventDefault();
  //   emailjs
  //     .send(
  //       "service_erub5wy",
  //       "template_k44rbkq",
  //       state,
  //       "user_45rMhZq99Crl2k3QCpJA2"
  //     )
  //     .then(
  //       (result) => {
  //         if (result.text) {
  //           setState({ full_name: "", email: "", message: "" });
  //           setShowSuccess(true);
  //           setTimeout(() => {
  //             setShowSuccess(false);
  //           }, 3000);
  //         }
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };

  // const onReset = () => {
  //   setState({ full_name: "", email: "", message: "" });
  // };

  const handleSmoothScroll = (id: string) => {
    let destination: any = document.querySelector(id);
    destination.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="wrapper">
      <div className="header">
        <nav
          className="navbar navbar-default navbar-fixed-top"
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="https://kaushalchheda.com/#">
                <span className="logo">
                  <i className="fa fa-xing logo-icon"></i>
                </span>
              </a>
            </div>

            {/* <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="https://www.kaushalchheda.com/#">Admin</a>
                </li>
              </ul>
            </div> */}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a
                    onClick={() => {
                      handleSmoothScroll("#home");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleSmoothScroll("#work");
                    }}
                  >
                    Work
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleSmoothScroll("#skills");
                    }}
                  >
                    Skills
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleSmoothScroll("#profile");
                    }}
                  >
                    Profile
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleSmoothScroll("#contact");
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div id="home" className="portfolio">
        <br />
        <br />
        <div className="padd">
          <div className="container">
            <div className="row">
              <br />
              <div className="col-md-6">
                <div className="hero animated animation fadeIn">
                  <h2>Kaushal Chheda</h2>
                  <hr />
                  <span>
                    Current Role: Technical Architect(Hands On) - Investment
                    Bank
                  </span>
                  <img
                    src={"assets/img/kaushal.JPG"}
                    className="img-responsive img-circle img-thumbnail"
                    alt=""
                  />
                  <div className="social">
                    <a
                      href="https://kaushalchheda.com/#"
                      title="Coming soon"
                      className="facebook"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      href="https://kaushalchheda.com/#"
                      title="Coming soon"
                      className="twitter"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href="https://kaushalchheda.com/#"
                      title="Coming soon"
                      className="google-plus"
                    >
                      <i className="fa fa-google-plus"></i>
                    </a>
                    <a
                      href="https://kaushalchheda.com/#"
                      title="Coming soon"
                      className="linkedin"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                  {/* <p>Hi! I am a senior creative designer and technology specialist web developer, serving my customers for more than 15 years. For me, a creative technologist is somebody who has an inner artist as well as an inner geek. It is a combination that can make for a sound understanding of the technical and the aesthetic.</p> */}
                  <p>
                    Hi! I'm a senior architect/developer helping my customers
                    build seamless digital experiences that combine beautiful
                    design with efficient functionality. With a software
                    engineering foundation and more than 15 years of commercial
                    experience, I bring along a wealth of knowledge and
                    experience from building a team to supporting an existing
                    team, helping with technical solutions and working alongside
                    team members to overcome any existing challenges.
                  </p>
                  <div className="hero-btn">
                    {/* <a href="/Content/KAUSHALCHHEDACV.pdf" download="KAUSHALCHHEDACV.pdf" target="blank" className="btn btn-info">Download <i className="fa fa-download br-lblue"></i></a>&nbsp;
                    <a href="/Content/KAUSHALCHHEDACV.pdf" download="KAUSHALCHHEDACV.pdf" target="blank" className="btn btn-danger">Print Resume <i className="fa fa-print br-red"></i></a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="objective animated animation fadeIn">
                  <h3>
                    <i className="fa fa-book br-red"></i> Career Objective
                  </h3>
                  <div className="objective-quote">
                    <span className="quote">“</span>

                    <blockquote>
                      <p>
                        Learning is an integral part of growing. My aspirations
                        in life are to continue crafting my passions in building
                        products and trading applications, working with AI/ML,
                        web development, building interpersonal relationships,
                        and being a leader who leads by example.
                      </p>
                    </blockquote>
                    <div className="clearfix"></div>
                  </div>

                  <h3>
                    <i className="fa fa-phone br-lblue"></i> Contact Details
                  </h3>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-item">
                        <i className="fa fa-map-marker br-green"></i>
                        <p>
                          Berkeley Suite
                          <br /> 35 Berkeley Square,
                          <br /> Mayfair, London,
                          <br /> W1J 5BF
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-item">
                        <i className="fa fa-microphone br-purple"></i>
                        <p>
                          +44 77337 22222
                          <br />
                          <a href="mailto:kaushalchheda@gmail.com">
                            kaushalchheda@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="work" className="work padd">
          <div className="container">
            <div className="heading">
              <h2>Work</h2>

              <h6>15+ years of commercial experience</h6>
              <hr />
              <br />
              <br />
              <p>
                Over the years I have work in many areas of business for Large
                Corporates, Investment Banks and many financial institutes;
                focusing on helping customers on upgrades with latest tech and
                problem solving for the existing challenges. Here are few names
                of my recent end clients.
              </p>
            </div>
            <div className="work-content">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/willis-towers-watson.png"}
                        className="img-responsive clients-image"
                        alt="Willis Towers Watson"
                      />

                      <div className="work-hover"></div>

                      {/* <a href="https://www.metoffice.gov.uk/binaries/content/gallery/metofficegovuk/images/research/weather/satimageofthemonth/eeda11_202303210615_scaled_1500x1500_credits.jpg" className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      {/* <a href={"assets/img/willis-towers-watson.png"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a
                        href="https://www.willistowerswatson.com/"
                        target="blank"
                      >
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">
                        Willis Towers Watson
                      </a>
                    </h4>
                    <p>
                      Global Risk Management, Insurance Brockerage and Advisor
                    </p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/sainsbury.jpg"}
                        className="img-responsive clients-image"
                        alt="Sainsbury Bank"
                      />

                      <div className="work-hover"></div>

                      {/* <a href="http://kaushalchheda.com/img/work/sainsbury.jpg" className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      {/* <a href={"assets/img/sainsbury.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a
                        href="https://www.sainsburysbank.co.uk/"
                        target="blank"
                      >
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">Sainsbury Bank</a>
                    </h4>
                    <p>Personal and Commercial Banking</p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/hsbc.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/hsbc.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="https://www.business.hsbc.uk/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">HSBC</a>
                    </h4>
                    <p>Commercial Banking</p>
                  </div>
                </div>

                <div className="clearfix visible-sm"></div>
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/lloyds.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/lloyds.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a
                        href="https://www.lloydsbank.com/business/home.asp"
                        target="blank"
                      >
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">LLOYDS Bank</a>
                    </h4>
                    <p>British Retail and Commercial bank</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/rbs.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/rbs.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a
                        href="https://www.business.rbs.co.uk/business.html"
                        target="blank"
                      >
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">
                        Royal Bank of Scotland
                      </a>
                    </h4>
                    <p>Banking and Insurance company</p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/asos.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/asos.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="http://www.asos.com/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">ASOS</a>
                    </h4>
                    <p>Leading Online Retailer</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/discovery.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/discovery.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="http://corporate.discovery.com/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">
                        Discovery Communications
                      </a>
                    </h4>
                    <p>Global Media and Television Group</p>
                  </div>
                </div>
                <div className="clearfix visible-sm"></div>

                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/tes.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/tes.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="https://www.tes.co.uk/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">TES</a>
                    </h4>
                    <p>World largest online network for teachers</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/bg.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/bg.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="https://www.britishgas.co.uk/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">Centrica</a>
                    </h4>
                    <p>Energy Company</p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/hotels4u.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/hotels4u.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="https://www.thomascook.com/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">Thomas Cook</a>
                    </h4>
                    <p>Global travel company</p>
                  </div>
                </div>

                <div className="clearfix visible-sm"></div>
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/dst.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/dst.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="http://www.dstsystems.com/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">DST</a>
                    </h4>
                    <p>Business Solutions Provider</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="work-item animated">
                    <div className="work-img">
                      <img
                        src={"assets/img/saga.jpg"}
                        className="img-responsive clients-image"
                        alt=""
                      />

                      <div className="work-hover"></div>

                      {/* <a href={"assets/img/saga.jpg"} className="work-photo"><i className="fa fa-search-plus icon-left"></i></a> */}
                      <a href="http://www.saga.co.uk/" target="blank">
                        <i className="fa fa-unlink icon-right"></i>
                      </a>
                    </div>

                    <h4>
                      <a href="https://kaushalchheda.com/#">Saga</a>
                    </h4>
                    <p>Insurance Company</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="skills" className="skill padd">
          <div className="container">
            <div className="heading">
              <h2>My Skills</h2>

              <h6>
                Technical skill is mastery of complexity, while creativity is
                mastery of simplicity
              </h6>
              <hr />
              <br />
            </div>
            <div className="skill-content">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="skill-item">
                    <div
                      style={{
                        display: "inline",
                        width: "200px",
                        height: "200px",
                      }}
                    >
                      {/* <canvas
                        width="400"
                        height="400"
                        style={{ width: "200px", height: "200px" }}
                      ></canvas> */}
                      <input
                        className="knob knob-one"
                        data-angleoffset="-125"
                        data-anglearc="250"
                        data-bgcolor="#e5e5e5"
                        data-fgcolor="#f75353"
                        value="0"
                        data-end="90"
                        readOnly={true}
                        style={{
                          width: "104px",
                          height: "66px",
                          position: "absolute",
                          verticalAlign: "middle",
                          marginTop: "66px",
                          marginLeft: "-152px",
                          border: "0px",
                          background: "none",
                          font: "bold 40px Arial",
                          textAlign: "center",
                          color: "rgb(247, 83, 83)",
                          padding: "0px",
                          appearance: "none",
                        }}
                      />
                    </div>

                    <h4>Front End Technologies</h4>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="skill-item">
                    <div
                      style={{
                        display: "inline",
                        width: "200px",
                        height: "200px",
                      }}
                    >
                      {/* <canvas
                        width="400"
                        height="400"
                        style={{ width: "200px", height: "200px" }}
                      ></canvas> */}
                      <input
                        className="knob knob-two"
                        data-angleoffset="-125"
                        data-anglearc="250"
                        data-bgcolor="#e5e5e5"
                        data-fgcolor="#32c8de"
                        value="0"
                        data-end="80"
                        readOnly={true}
                        style={{
                          width: "104px",
                          height: "66px",
                          position: "absolute",
                          verticalAlign: "middle",
                          marginTop: "66px",
                          marginLeft: "-152px",
                          border: "0px",
                          background: "none",
                          font: "bold 40px Arial",
                          textAlign: "center",
                          color: "rgb(50, 200, 222)",
                          padding: "0px",
                          appearance: "none",
                        }}
                      />
                    </div>

                    <h4>Back End Technologies &amp; Devops</h4>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="skill-item">
                    <div
                      style={{
                        display: "inline",
                        width: "200px",
                        height: "200px",
                      }}
                    >
                      {/* <canvas
                        width="400"
                        height="400"
                        style={{ width: "200px", height: "200px" }}
                      ></canvas> */}
                      <input
                        className="knob knob-three"
                        data-angleoffset="-125"
                        data-anglearc="250"
                        data-bgcolor="#e5e5e5"
                        data-fgcolor="#51d466"
                        value="0"
                        data-end="95"
                        readOnly={true}
                        style={{
                          width: "104px",
                          height: "66px",
                          position: "absolute",
                          verticalAlign: "middle",
                          marginTop: "66px",
                          marginLeft: "-152px",
                          border: "0px",
                          background: "none",
                          font: "bold 40px Arial",
                          textAlign: "center",
                          color: "rgb(81, 212, 102)",
                          padding: "0px",
                          appearance: "none",
                        }}
                      />
                    </div>

                    <h4>Complex Trading Application</h4>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="skill-item">
                    <div
                      style={{
                        display: "inline",
                        width: "200px",
                        height: "200px",
                      }}
                    >
                      {/* <canvas
                        width="400"
                        height="400"
                        style={{ width: "200px", height: "200px" }}
                      ></canvas> */}
                      <input
                        className="knob knob-four"
                        data-angleoffset="-125"
                        data-anglearc="250"
                        data-bgcolor="#e5e5e5"
                        data-fgcolor="#f7a253"
                        value="0"
                        data-end="90"
                        readOnly={true}
                        style={{
                          width: "104px",
                          height: "66px",
                          position: "absolute",
                          verticalAlign: "middle",
                          marginTop: "66px",
                          marginLeft: "-152px",
                          border: "0px",
                          background: "none",
                          font: "bold 40px Arial",
                          textAlign: "center",
                          color: "rgb(247, 162, 83)",
                          padding: "0px",
                          appearance: "none",
                        }}
                      />
                    </div>

                    <h4>Technical Architect &amp; Problem Solving</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="experience-content">
              <h3>My Experience</h3>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="exp-progress">
                    <span className="exp-title br-green">React/Angular</span>

                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-success"
                        role="progressbar"
                        data-end="95"
                      >
                        <span className="pull-right"></span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="exp-progress">
                    <span className="exp-title br-red">API &amp; Devops</span>

                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-danger"
                        role="progressbar"
                        data-end="90"
                      >
                        <span className="pull-right"></span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="exp-progress">
                    <span className="exp-title br-lblue">.NET/Python</span>

                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-info"
                        role="progressbar"
                        data-end="70"
                      >
                        <span className="pull-right"></span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="exp-progress">
                    <span className="exp-title br-orange">Architect</span>

                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-warning"
                        role="progressbar"
                        data-end="85"
                      >
                        <span className="pull-right"></span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <h6>Recently Used Technologies</h6>

                  <h4>
                    React, TypeScript, Angular, D3FC, Big Data, Complex Trading
                    Charts, Micro Frontend, API, Storybook, Similar to Nasdaq
                    Product
                  </h4>

                  <p>
                    My current project is a one-of-a-kind complex trade
                    surveillance application that I initiated from scratch,
                    taking a leadership role and constructing much of the
                    architecture alongside enhancements to the existing system.
                    I was deeply involved in every aspect, from structuring the
                    project to writing code on a daily basis. In addition to
                    laying the foundation of the application, my responsibility
                    involved in conducting interviews and recruiting team
                    members. I was also responsible for building complex charts
                    capable of processing 24 hours of data, ranging from minute
                    intervals (1440 records) down to milliseconds, microseconds,
                    and nanoseconds (hundreds of millions of records) and
                    plotting multi-instruments/exchanges data with custom
                    drawings.
                  </p>
                </div>
              </div>
              <div className="row">
                <h4>Recent Clients:</h4>

                <div className="col-md-4 col-sm-4">
                  <div className="exp-item">
                    <i className="fa br-orange">C</i>

                    <h5>Centrica</h5>

                    <p>
                      <strong>Lead UX Engineer: </strong> Design and develop a
                      new Design System with leading on architecture design,
                      helping building processes and development of the system
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4">
                  <div className="exp-item">
                    <i className="fa br-red">W</i>

                    <h5>Willis Towers Watson</h5>

                    <p>
                      <strong>Frontend Developer: </strong> Design and develop
                      the front end architecture of the risk management
                      products, insurance brokerage website and advisory portal
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4">
                  <div className="exp-item">
                    <i className="fa br-blue">IE</i>

                    <h5>IE Digital</h5>

                    <p>
                      <strong>Senior Frontend Developer:</strong> Desgin and
                      develop the personal and commercial banking
                      websites/applications for world leading banks
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="profile" className="profile padd">
          <div className="container">
            <div className="heading">
              <h2>Profile</h2>

              <h6>Passionate about technology</h6>
              <hr />

              <p> </p>
            </div>
            <div className="profile-content animated">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={"assets/img/kaushal.JPG"}
                    className="img-responsive img-circle"
                    alt=""
                  />

                  <h2>Kaushal Chheda</h2>
                  <span className="meta">Msc in Software Engineering</span>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="profile-item">
                        <h3 className="br-lblue">
                          <i className="fa fa-laptop"></i> Technologies
                        </h3>

                        <p>
                          <b>Javascript Libraries:</b> React, Angular, Backbone,
                          Node, Knockout, Underscore, AngularUI
                        </p>
                        <p>
                          <b>Microsoft Stack:</b> ASP.NET, C#, VB.Net, SQL
                          Server
                        </p>
                        <p>
                          <b>Mobile:</b> PhoneGap, Xcode, ngCordova, Object C{" "}
                        </p>
                        <p>
                          <b>Front End:</b> HTML5, TypeScript, CSS3, LESS, SASS,
                          Bootstrap
                        </p>
                        <p>
                          <b>Services:</b> Web services, WCF, WebAPI2, Service
                          Stack
                        </p>
                        <p>
                          <b>Testing:</b> Jasmine, Karma, Nunit, Selenium,
                          MSTest
                        </p>
                        <p>
                          <b>Versioning:</b> GIT, SVN, TFS, Team City, Bamboo
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="profile-item">
                        <h3 className="br-green">
                          <i className="fa fa-cogs"></i> Industry Experience
                        </h3>

                        <ul>
                          <li>
                            <p>Personal and Commercial Banking</p>
                          </li>
                          <li>
                            <p>Travel and Retail Industry</p>
                          </li>
                          <li>
                            <p>Energy &amp; Electricals Associations</p>
                          </li>
                          <li>
                            <p>Data Insights &amp; Marketing Companies</p>
                          </li>
                          <li>
                            <p>Finance and Insurance Sector</p>
                          </li>
                          <li>
                            <p>Media and Television Industry</p>
                          </li>
                          <li>
                            <p>Education and Digital Sector</p>
                          </li>
                          <li>
                            <p>Digital Media Agencies</p>
                          </li>
                          <li>
                            <p>Government Projects</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-content animated">
              <div className="row">
                <div className="col-md-4">
                  <h6 className="br-orange">
                    <i className="fa fa-book"></i> Educational Qualification
                  </h6>

                  <p className="side-para"></p>
                  <ul id="mytab" className="nav nav-tabs">
                    <li className="active">
                      <a
                        href="https://kaushalchheda.com/#pg"
                        className="br-red education-icons"
                        data-toggle="tab"
                      >
                        PG
                      </a>
                      <div className="education-text">
                        Masters in Software Engineering
                        <br /> University of Hertfordshire
                      </div>
                    </li>
                    <li>
                      <a
                        href="https://kaushalchheda.com/#ug"
                        className="br-green education-icons"
                        data-toggle="tab"
                      >
                        UG
                      </a>
                      <div className="education-text">
                        Bachelors of engineering degree
                        <br /> in Information Technology
                      </div>
                    </li>
                    <li>
                      <a
                        href="https://kaushalchheda.com/#sc"
                        className="br-lblue education-icons"
                        data-toggle="tab"
                      >
                        SC
                      </a>
                      <div className="education-text">
                        Diploma in Information Technology
                      </div>
                    </li>
                  </ul>
                  <p></p>
                </div>

                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="profile-item">
                        <h3 className="br-red">
                          <i className="fa fa-shield"></i> Work Type
                        </h3>

                        <p>
                          <b>Job Type: </b>Contract, Permanent & FTC
                        </p>
                        <p>
                          <b>Other Job: </b>Complete project delivery or
                          Resources
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="profile-item">
                        <h3 className="br-purple">
                          <i className="fa fa-map-marker"></i> Location
                        </h3>

                        <p>
                          <b>Onsite: </b>Available within UK & USA
                          <br />
                          <b>Remote: </b>Fleixble
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div className="tab-pane fade in active" id="pg">
                      <h3>
                        Msc in Software Engineering, University of Hertfordshire
                      </h3>
                      <h5>Campus: Hatfield, Hertfordshire</h5>
                      <p>
                        Dissertation: Comparison on different software
                        development life cycle processes and further comparison
                        on agile methodologies
                      </p>
                    </div>

                    <div className="tab-pane fade" id="ug">
                      <h3>
                        Msc in Software Engineering, University of Hertfordshire
                      </h3>
                      <h5>Campus: Hatfield, Hertfordshire</h5>
                      <p>
                        Dissertation: Comparison of different software
                        development life cycle processes and further comparison
                        on agile methodologies
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-content animated">
              <div className="row">
                <div className="col-md-4">
                  <h6 className="br-purple">
                    <i className="fa fa-briefcase"></i> Additional resources
                  </h6>

                  <p className="side-para">
                    I operate my business using limited company and have more
                    resources as part of the same company with niche technical
                    speciality to cover additional work. <br />
                    Please get in touch for more details.
                  </p>
                </div>
                <div className="col-md-8">
                  <div className="tab-content">
                    <div className="tab-pane fade in active" id="pg">
                      <h3>Other qualification</h3>
                      <h5>
                        Microsoft .Net training and certification from private
                        institute
                      </h5>
                      <h5>
                        Photoshop training and certification from private
                        institute
                      </h5>
                      <p>
                        Experience working on search engine optimisation, google
                        analytics, google adwords, user journey workflow,
                        marketing campaigns, PPC, data segmentation, tracking
                        and online sales optimisation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" className="contact padd">
          <div className="container">
            <div className="heading">
              <h2>Contact</h2>

              <h6>Get in touch</h6>
              <hr />
              <br />
              <br />
            </div>
            <div className="contact-content">
              <div className="row">
                {/* <div className="col-md-5">
                  {showSuccess && (
                    <div className="alert alert-success">
                      <strong>Success!</strong> Thank you for getting in touch!.
                    </div>
                  )}
                  <div className="contact-form animated delay-one">
                    <h3>Contact Form</h3>
                    <form role="form">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name"
                          onChange={handleChange}
                          name="full_name"
                          value={state.full_name}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          onChange={handleChange}
                          name="email"
                          value={state.email}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="message"
                          rows={5}
                          placeholder="Message"
                          onChange={handleChange}
                          name="message"
                          value={state.message}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          onClick={sendEmail}
                          disabled={
                            state.full_name && state.email && state.message
                              ? false
                              : true
                          }
                          className="btn btn-info"
                        >
                          Send
                        </button>{" "}
                        &nbsp;
                        <button
                          type="reset"
                          onClick={onReset}
                          className="btn btn-default"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-widget br-red animated delay-one">
                        <i className="fa fa-map-marker c-icon"></i>
                        <div className="cw-details">
                          <h4>Address</h4>

                          <p>
                            Berkeley Suite, 35 Berkeley Square, Mayfair, London,
                            W1J 5BF
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-widget br-lblue animated delay-two">
                        <i className="fa fa-phone c-icon"></i>
                        <div className="cw-details">
                          <h4>Phone Number</h4>

                          <span>
                            <i className="fa fa-phone-square"></i>{" "}
                            <a href="https://www.kaushalchheda.com/#">
                              +44 77337 22222
                            </a>
                          </span>
                          <span>
                            <i className="fa fa-envelope"></i>{" "}
                            <a href="https://www.kaushalchheda.com/#">
                              kaushalchheda@gmail.com
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-widget br-green animated delay-one">
                        <i className="fa fa-group c-icon"></i>
                        <div className="cw-details">
                          <h4>Meeting</h4>

                          <p>
                            Available on
                            <br /> Appointments
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="contact-widget br-purple animated delay-two">
                        <i className="fa fa-briefcase c-icon"></i>
                        <div className="cw-details">
                          <h4>Business Hrs</h4>

                          <span>
                            <i className="fa fa-clock-o"></i> Mon-Fri - 09:00 to
                            18:00
                          </span>
                          <span>
                            <i className="fa fa-clock-o"></i> Sat - 10:00 to
                            16:00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="social">
            <a
              href="https://www.kaushalchheda.com/#"
              title="Coming soon"
              className="facebook"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://www.kaushalchheda.com/#"
              title="Coming soon"
              className="twitter"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.kaushalchheda.com/#"
              title="Coming soon"
              className="google-plus"
            >
              <i className="fa fa-google-plus"></i>
            </a>
            <a
              href="https://www.kaushalchheda.com/#"
              title="Coming soon"
              className="linkedin"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </div>

          <div className="copy-right">
            <p>
              Copyright <a href="https://www.kaushalchheda.com/#">GCL</a>
            </p>
          </div>
        </div>
      </div>
      <span className="scroll-to-top" style={{ display: "none" }}>
        <a href="https://www.kaushalchheda.com/#">
          <i className="fa fa-chevron-up"></i>
        </a>
      </span>
    </div>
  );
}
