/**
 * Check User Name 
 * @param myForm string
 * @returns  Error Message
 */
export const ValidateEmail = (myForm: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(myForm)) {
        return "";
    }
    return "Username is changed, please email at kaushalchheda@gmail.com for the new access details";
}